<template>
  <b-field
    :label="label"
  >
    <b-input
      :value="value"
      @input="update"
    />
  </b-field>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: String | Number,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    update (value) {
      this.$emit('input', value)
    }
  }
}
</script>
