<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        Check, if Sanis ID is not already used
      </p>
    </header>

    <section class="modal-card-body">
      <a-card
        :editable="false"
      >
        <b-field
          label="Select Sanis item type"
        >
          <b-select
            v-model="format"
          >
            <option value="part">
              Part
            </option>
            <option value="breakdown">
              Breakdown
            </option>
          </b-select>
        </b-field>

        <b-field
          label="Type Sanis ID"
        >
          <b-input
            v-show="format === 'part'"
            v-model="id"
            v-cleave="partFormat"
            maxlength="12"
            has-counter
            @input="checkIfSanisIdExists"
          />

          <b-input
            v-show="format === 'breakdown'"
            v-model="id"
            v-cleave="breakdownFormat"
            maxlength="11"
            has-counter
            @input="checkIfSanisIdExists"
          />
        </b-field>
        <b-message
          v-if="isObject(existingPart)"
          :title="`Part of the ${id} ID exists!`"
          type="is-danger"
          has-icon
          aria-close-label="Close message"
        >
          <rough-document
            :manual-doc="existingPartDocRef"
          />
        </b-message>

        <b-message
          v-if="isString(existingPart)"
          :title="`ID ${id} is not in the database`"
          type="is-success"
          has-icon
          aria-close-label="Close message"
        >
          <button
            class="button is-success is-pulled-right"
            type="button"
            @click="create()"
          >
            <b-icon icon="wrench" />
            <span>Add part</span>
          </button>
        </b-message>
      </a-card>
    </section>
    <footer class="modal-card-foot">
      <button
        class="button"
        type="button"
        @click="$parent.close()"
      >
        Cancel
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isString, isObject } from 'lodash'
import Cleave from 'cleave.js'
import CInput from '@/components/Form/CInput'

const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  directives: { cleave },
  components: {
    CInput
  },
  data () {
    return {
      id: '',
      existingPart: null,
      format: 'part',
      formats: ['part', 'breakdown'],
      partFormat: {
        delimiter: '-',
        blocks: [3, 3, 4]
      },
      breakdownFormat: {
        delimiter: '-',
        blocks: [4, 2, 3]
      }
      // cleave_format: { creditCard: true }
    }
  },
  computed: {
    existingPartDocRef () {
      return {
        module: 'parts',
        id: this.id
      }
    }
  },
  methods: {
    isString,
    isObject,
    async checkIfSanisIdExists (id) {
      if (id.length === (this.format === 'part' ? 12 : 11)) {
        this.existingPart = await this.$store.dispatch(`parts/fetchById`, id)
      }
    },
    async create () {
      const id = await this.$store.dispatch(`parts/insert`, { id: this.id })
      this.$router.push({ name: 'part', params: { id } })
      this.close()
    },
    close () {
      this.$parent.close()
    }
  }
}
</script>

<style scoped>
.modal-card {
    width: auto;
}
.modal-card-body {
    width: auto;
}
</style>
